import Head from 'next/head'
import {
  BestStrategiesSection,
  AdvantagesSection,
  MainSection,
  CreateYourStrategySection,
  LandingLayout,
  BackedBestSection,
  FAQ,
} from '../newDesign/components/sections'
import { UseLaunchKeystones } from '../newDesign/hooks/UseKeystones'
import { Layout } from '../components/page/Layout'

const HomePage = () => {
  UseLaunchKeystones()

  return (
    <>
      <Head>
        <title>Enso Finance</title>
        <meta
          name="description"
          content="Social trading redefined. Create Alpha strategies, share and compete with your friends, community or DAO. Discover and create open defi strategies page."
        />
        <meta
          name="keywords"
          content="enso, enso finance, finance, defi, enso ethereum, enso finance ethereum, social trading, defi strategies, crypto, crypto strategies"
        />
        <meta property="og:title" content="Enso Finance" />
        <meta
          property="og:description"
          content="Social trading redefined. Create Alpha strategies, share and compete with your friends, community or DAO. Discover and create open defi strategies page."
        />
        <meta name="twitter:creator" content="@EnsoFinance" />
        <meta name="twitter:title" content="Enso Finance" />
        <meta
          name="twitter:description"
          content="Social trading redefined. Create Alpha strategies, share and compete with your friends, community or DAO. Discover and create open defi strategies page."
        />
        <meta property="og:image" content="/images/home/thumbnail.png" />
        <link rel="preload" href="/images/home/loopPortalPlaceholder.png" as="image" type="image/png" />
      </Head>

      {/*TODO: transfer styles context from landing page to providers*/}

      <Layout>
        <LandingLayout>
          <MainSection />
          <AdvantagesSection />
          <BestStrategiesSection />
          <CreateYourStrategySection />
          <FAQ />
          <BackedBestSection />
        </LandingLayout>
      </Layout>
    </>
  )
}

export default HomePage
