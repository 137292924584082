import React from 'react'
import styled from 'styled-components'
import { Colors, Transitions } from '../../constants'
import { VampireClaimModal } from './VampireClaim'

interface LayoutProps {
  children: React.ReactNode
  className?: string
}

export function Layout({ className, children }: LayoutProps) {
  return (
    <>
      <LayoutComponent className={className}>{children}</LayoutComponent>
    </>
  )
}

const LayoutComponent = styled.div<{ backColor?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  position: relative;
  width: 100%;
  min-height: 100%;
  background-color: ${Colors.White};
  z-index: 0;
  transition: ${Transitions.all};

  &:before {
    content: '';
    position: fixed;
    opacity: 0.2;
    top: -300px;
    position: absolute;
    width: 100%;
    left: 50px;
    height: 1360px;
    background: radial-gradient(43.32% 43.32% at 50% 50%, rgba(217, 107, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
    mix-blend-mode: normal;
    pointer-events: none;
    transition: background-color ${Transitions.duration} ease;
    z-index: -1;
  }
`
